<template>
  <div class="search-page page">
    <BreadcrumbsComponent title="Поиск" />
    <div class="container-padding">
      <div class="page__inner">
        <BenefitsComponent />
        <div v-if="showProducts" ref="anchor" class="search-page__results">
          <span class="search-page__results-count">
            Найдено результатов: {{ products.paginatorInfo.total }}
          </span>
          <ProductsComponent
            :products="products.data"
            :banners="banners.data"
            :banners_wide="banners_wide.data"
            :loading="loading"
          />
        </div>
        <span v-else>Ничего не найдено</span>
        <PaginationComponent
          :page="productsPaginator.page"
          :first="productsPaginator.first"
          :list="products"
          @change="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import BenefitsComponent from "components/BenefitsComponent.vue";
import ProductsComponent from "components/ProductsComponent.vue";
import PaginationComponent from "components/Pagination.vue";
import SEARCH_PAGE from "@/graphql/pages/SearchPage.graphql";

let _isMobile = false;

export default {
  name: "SearchPage",
  async asyncData({ apollo, store, route, isMobile }) {
    _isMobile = isMobile;
    await apollo.defaultClient
      .query({
        query: SEARCH_PAGE,
        variables: {
          parent: true,
          title: route.query.title || undefined,
          first: _isMobile ? 12 : 16,
          banners_first: 2,
          banners_wide_first: 1,
        },
      })
      .then(({ data }) => {
        store.state.search_page.products = data.products_paginate;
        store.state.search_page.banners = data.banners_paginate;
        store.state.search_page.banners_wide = data.banners_wide;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  data() {
    return {
      productsPaginator: {
        page: 1,
        first: _isMobile ? 12 : 16,
      },
      bannersPaginator: {
        page: 1,
        first: 2,
      },
      bannersWidePaginator: {
        page: 1,
        first: 1,
      },
      loading: false,
    };
  },
  created() {
    this.load();
  },
  computed: {
    title() {
      return this.$route.query.title;
    },
    products() {
      return this.$store.state.search_page.products;
    },
    showProducts() {
      return this.products && this.products.data && this.products.data.length;
    },
    showPagination() {
      return (
        this.showProducts && Math.ceil(this.products.paginatorInfo.total / this.productsPaginator.first) > 1
      );
    },
    banners() {
      return this.$store.state.search_page.banners || {};
    },
    banners_wide() {
      return this.$store.state.search_page.banners_wide || {};
    },
  },
  methods: {
    async paginate(page) {
      if (!this.loading) {
        this.productsPaginator.page = page;
        this.bannersPaginator.page = page;
        this.bannersWidePaginator.page = page;
        this.loading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: SEARCH_PAGE,
          variables: {
            parent: true,
            title: this.title || undefined,
            first: this.productsPaginator.first,
            banners_first: this.bannersPaginator.first,
            banners_wide_first: this.bannersWidePaginator.first,
            page: this.productsPaginator.page,
            banners_page: this.bannersPaginator.page,
            banners_wide_page: this.bannersWidePaginator.page,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.$store.state.search_page.products = data.products_paginate;
          this.$store.state.search_page.banners = data.banners_paginate;
          this.$store.state.search_page.banners_wide = data.banners_wide;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    scrollUp() {
      if (this.$refs.anchor) {
        let top = this.$refs.anchor.$el ? this.$refs.anchor.$el.offsetTop : this.$refs.anchor.offsetTop;
        if (window.scrollY > top) {
          window.scrollTo({ top: top - 120 });
        }
      }
    },
  },
  components: {
    PaginationComponent,
    ProductsComponent,
    BenefitsComponent,
    BreadcrumbsComponent,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: "%s - купить в ОРТОСИТИ",
    };
  },
};
</script>

<style lang="stylus">
.search-page {
  &__results {
    display flex
    flex-direction column
    gap: 10px

    &-count {
      font-size: 0.875em;
      line-height: 20px;
    }
  }
}
</style>
